<template>
  <div class="backup">
    <transition name="fade" mode="out-in" @after-enter="setFocus()">
      <div class="backup__content">
        <div class="backup__form" @keyup.enter="recoverCurrentBackup()">
          <div class="l-col">
            <label class="typo__label">{{ $t('label') }}</label>
            <label class="backup-form__field backup-form__field--input">
              <strong>{{ value }}</strong>
            </label>
            <label class="typo__label"> ?</label>
          </div>
          <div class="backup__send">
            <div class="backup__send-container">
              <base-button slot="footerEnd" class="backup__send-btn" @click="recoverCurrentBackup()"
                >{{ $t('btn.send') }}
              </base-button>
            </div>
            <div class="backup__send-container">
              <base-button
                slot="footerEnd"
                color="error"
                theme="outlined"
                class="backup__send-btn"
                @click="closeModal()"
                >{{ $t('btn.close') }}
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import showErrorModal from '@/mixins/showErrorModal';
import updateServerInfo from '@/layouts/VPS/mixins/updateServerInfo';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import Vue from 'vue';
export default {
  name: 'RecoverCurrentBackup',
  components: {},
  mixins: [setFocus, showErrorModal, updateServerInfo],
  props: {
    backup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      nameBackup: '',
      currentTaskUuid: '',
      timerCnt: 0,
    };
  },
  computed: {
    value() {
      return this.backup.name;
    },
    id() {
      return this.$route.name === 'BackupMain' ? '' : this.backup.instanceUuid;
    },
  },
  mounted() {},
  methods: {
    async recoverCurrentBackup() {
      this.showLoadingModal({ title: this.$t('title') });
      const data = await this.recoverBackup();
      if (this.$store.state.moduleVPS.moduleVpsOrder.stateBackup === 'success')
        this.showResModal('success', data);
      this.$modals.close({ name: 'RecoverCurrentBackup' });
      await this.$store.dispatch('moduleVPS/moduleVpsOrder/setBackupsInfo', this.id);
      // },
    },
    showLoadingModal(props = {}) {
      if (!this.modal) this.makeModal(props);
      Vue.set(this.modal, 'text', null);
      Vue.set(this.modal, 'component', BaseLoader);
      Vue.set(this.modal, 'closable', false);
      Vue.set(this.modal, 'footer', false);
    },
    makeModal(props = {}) {
      this.$modals.open({
        name: 'AddModal',
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
        onDismiss: () => (this.modal = null),
        ...props,
      });
    },
    showResModal(res, props = {}) {
      if (!this.modal) this.makeModal(props);
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'text', res);
      Vue.set(this.modal, 'footer', {
        cancel: {
          props: { title: this.$t('close') },
          on: { click: () => this.$modals.close() },
        },
      });
      if (this.modal.footer.confirm) {
        Vue.set(this.modal.footer, 'confirm', false);
        this.$store.commit('moduleVPS/moduleVpsOrder/SET_STATE_BACKUP', '');
      }
    },
    updateBackupInfo(callback) {
      //TODO DRY
      if (this.timerCnt < 5) this.timerCnt += 1;
      this.timerId = setTimeout(() => {
        this.fetchBackupInfo(this.currentTaskUuid).then(data => {
          this.$store.commit('moduleVPS/moduleVpsOrder/SET_STATE_BACKUP', data.State);
          // console.log(data.State);
          if (data.State === 'processing') {
            this.updateBackupInfo(callback, this.currentTaskUuid);
          } else if (data.State === 'failure') {
            this.showResModal(data.Error);
          } else {
            this.timerCnt = 0;
            if (callback && typeof callback === 'function') callback();
            clearTimeout(this.timerId);
            this.$store.dispatch('moduleVPS/moduleVpsOrder/setBackupsInfo', this.id);
            this.$store.commit('moduleVPS/moduleVpsOrder/SET_STATE_BACKUP', '');
            this.showResModal(this.$t('restore.success', { name: this.value }));
          }
        });
      }, 1000 * this.timerCnt);
    },
    recoverBackup() {
      const payload = {
        serverid: this.id,
        action: 'restore',
        method: 'post',
        backupuuid: this.backup.uuid,
      };
      return new Promise(resolve => {
        this.$store
          .dispatch('moduleVPS/moduleVpsOrder/setBackupAction', payload)
          .then(async response => {
            this.currentTaskUuid = response.Uuid;
            await this.updateBackupInfo();
            resolve('success');
          })
          .catch(reject => {
            // console.log(reject);
            this.showError(reject.response.data.message);
            this.$modals.close({ name: 'RecoverCurrentBackup' });
            this.$modals.close({ name: 'AddModal' });
          });
      });
    },
    closeModal() {
      return this.$modals.close();
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Восстановление бэкапа",
    "label": "Вы хотите восстановить ",
    "restore" : {
      "success" : "Бэкап {name} успешно восстановлен"
    },
    "btn": {
      "send": "Восстановить",
      "close": "Отмена"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.backup-form {
  &__field {
    &  {
      margin-top: 1.25rem;
    }
  }
}
.backup__send {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;

  &-btn {
    margin-top: 20px
    margin-right: 0;
    margin-left: 1rem;
    +breakpoint(ms-and-up) {
      margin-top: 60px;
    }
  }

  &-container{
    display: flex;
    align-items: flex-end;

  }

}
</style>
