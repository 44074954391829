export default {
  computed: {
    list() {
      return this.$store.state.moduleVPS.list;
    },
    isLoading() {
      return this.$store.state.moduleVPS.isLoading;
    },
    listBackups() {
      return this.$store.getters['moduleVPS/moduleVpsOrder/listBackups'];
    },
  },
  methods: {
    fetchList() {
      return this.$store.dispatch('moduleVPS/fetchList');
    },
    fetchVPS() {
      const payload = {
        action: 'restore',
        method: 'get',
      };
      return new Promise(() => {
        this.$store
          .dispatch('moduleVPS/moduleVpsOrder/setBackupAction', payload)
          .then(response => {
            this.$store.commit('moduleVPS/moduleVpsOrder/setVpsList', response.Items);
          })
          .catch(reject => {
            this.showError(reject.response.data.message);
          });
      });
    },
  },
};
