import Vue from 'vue';

export default {
  methods: {
    makeModal(props = {}) {
      this.$modals.open({
        name: 'AddModal',
        // html: true,
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
        onDismiss: () => (this.modal = null),
        ...props,
      });
    },
    showResModal(res) {
      if (!this.modal) this.makeModal();
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'html', true);
      Vue.set(this.modal, 'text', res);
      Vue.set(this.modal, 'footer', {
        centered: true,
        confirm: {
          props: { title: this.$t('close') },
          on: { click: () => this.$modals.close() },
        },
      });
    },
  },
};
