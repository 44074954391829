export default {
  data() {
    return {
      oldState: '',
      timerId: '',
      timerCnt: 0,
      isProcessing: false,
    };
  },
  computed: {
    serverId() {
      return this.tariff.serverId;
    },
  },
  methods: {
    fetchServerInfo() {
      return this.$store.dispatch('moduleVPS/fetchServerInfo', { serverId: this.serverId });
    },
    fetchBackupInfo(uuid) {
      return this.$store.dispatch('moduleVPS/fetchBackupInfo', { serverId: uuid });
    },
    setBackupInfo(uuid) {
      // console.log(`setBackupInfo(${uuid})`);
      return this.$store.dispatch('moduleVPS/setBackupState', { serverId: uuid });
    },
    setVpsInfo() {
      return this.$store.dispatch('moduleVPS/fetchVpsCountInfo', {
        serverId: this.tariff.serverId,
      });
    },
    updateServerInfo(callback) {
      if (this.timerCnt < 5) this.timerCnt += 1;
      this.timerId = setTimeout(() => {
        this.fetchServerInfo().then(data => {
          if (data.State === this.oldState || data.State === 'unknown') {
            this.$store.commit('moduleVPS/SET_OTHER_INFO_ABOUT_TARIFF', {
              prop: 'serverState',
              value: 'processing',
              id: this.tariff.id,
            });
            this.updateServerInfo(callback);
          } else {
            this.$store.commit('moduleVPS/SET_OTHER_INFO_ABOUT_TARIFF', {
              prop: 'serverInfo',
              value: data,
              id: this.tariff.id,
            });

            this.oldState = '';
            this.timerCnt = 0;
            this.isProcessing = false;
            if (callback && typeof callback === 'function') callback();
            clearTimeout(this.timerId);
          }
        });
      }, 1000 * this.timerCnt);
    },
  },
};
